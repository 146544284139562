<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal()" v-if="items.length == 0">Nueva Fiel</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable 
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #fiscal_name="{item}"><td class="w400 text-left"><b>{{item.rfc}}</b><br>{{item.fiscal_name}}</td></template>
        <template #certificate_number="{item}"><td class="w400 text-center">{{item.certificate_number}}</td></template>
        <template #start_date="{item}"><td class="w200 text-center">{{item.start_date | date}}</td></template>
        <template #deadline="{item}"><td class="w200 text-center">{{item.deadline | date}}</td></template>
        <template #actions="{item}">
          <td class="table_actions b2">
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <FielModal ref="fielModal" @store="storeProcess"></FielModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import FielModal from './FielModal.vue'
import DeleteModal from '../../global/DeleteModal.vue'
import ws from '../../../services/fiel';
import store from '../../../store'

export default {
  name: 'FielTable',
  components: { FielModal, DeleteModal }, 
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'fiscal_name', label: 'Contribuyente'},
          {key: 'certificate_number', label: 'Número de Certificado'},
          {key: 'start_date', label: 'Emisión'},
          {key: 'deadline', label: 'Vigencia'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: []
    }
  },
  methods: {
    storeModal () {
      this.$refs.fielModal.storeModal();
    },
    updateModal (item) {
      this.$refs.fielModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Fiel", item.id, item.certificate_number);
    },
    async storeProcess(data) {
      this.loading();
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async deleteProcess(id) {
      this.loading();
      let response = await ws.delete(id);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  }
}
</script>
