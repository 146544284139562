<template>
  <div>
    <CRow>
      <CCol sm="12">
        <FielTable
          :items="fiel"
          hover
          striped
          border
          small
          fixed
          caption="Fiel (eFirma)"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import FielTable from '../../components/configuration/fiel/FielTable.vue'
import ws from '../../services/fiel';
import store from '../../store'

export default {
  name: 'Fiel',
  components: { FielTable },
  data: function () {
		return {
            fiel: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get(); 

    if(response.type == "success"){
      this.fiel = response.data;
    }
    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      let response = await ws.get();

      if(response.type == "success"){
        this.fiel = response.data;
      }
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
