<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="fielModal"
    >   
        <CForm id="fiel_form" method="POST" enctype="multipart/form-data">
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="cer_file"
                    id="cer_file"
                    label="Archivo .cer:"
                    :lazy="false"
                    :value.sync="$v.form.cer_file.$model"
                    :isValid="checkIfValid('cer_file')"
                    placeholder=""
                    autocomplete="cer_file"
                    invalidFeedback="El archivo .cer es obligatorio."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="key_file"
                    id="key_file"
                    label="Archivo .key:"
                    :lazy="false"
                    :value.sync="$v.form.key_file.$model"
                    :isValid="checkIfValid('key_file')"
                    placeholder=""
                    autocomplete="key_file"
                    invalidFeedback="El archivo .key es obligatorio."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  type="password"
                  name="password"
                  id="password"
                  label="Clave Privada:"
                  :lazy="false"
                  :value.sync="$v.form.password.$model"
                  :isValid="checkIfValid('password')"
                  placeholder=""
                  autocomplete="password"
                  invalidFeedback="La contraseña del certificado es obligatoria y debe tener al menos 8 caracteres."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"

export default { 
  name: 'FielModal',
  props: {

  },
  
  data () {
    return {
      fielModal: false,
      title: "Nueva Fiel", 
      color: "info",
      isEdit: false,
      form: {
        cer_file: '',
        key_file: '',
        password: ''
      },
      submitted: false
    }
  },
  methods: {
    storeModal () {
      this.fielModal = true;
      this.color = "info";
      this.title = 'Nueva Fiel';
      this.isEdit = false;
      this.submitted = false;
    },
    updateModal (item) {
      this.fielModal = true;
      this.color = "warning";
      this.title = 'Editar Fiel';
      this.isEdit = true;
      this.submitted = false;
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        const form = document.getElementById("fiel_form");
        const formData = new FormData(form);

        this.$emit("store", formData );

        this.closeModal();
      }
    },
    closeModal () {
      this.fielModal = false;

      this.submitted = false
      this.$v.$reset();

      this.form = {
        cer_file: '',
        key_file: '',
        password: ''
      };
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      cer_file: {
        required
      },
      key_file: {
        required
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },
}
</script>